import dashboard from "./dashboard";
// import listingManager from "./listing-manager";
import keywordManager from "./keyword-manager";
import marketManager from "./market-manager";
import topCharts from "./top-charts";
import marketplace from "./marketplace/marketplace";
import topCollections from "./top-collections";
// import keywordTracking from "./keyword-tracking";
import myApps from "./myApps";
import discord from "./discord";
import support from "./support";

// Array of sections
export default [
  ...dashboard,
  ...marketManager,
  ...keywordManager,
//   ...listingManager,
//   ...keywordTracking,
  ...topCollections,
  ...topCharts,
  ...marketplace,
  ...myApps,
  ...discord,
  ...support,
];
